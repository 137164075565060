var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10",staticStyle:{"height":"100vh"}},[_c('GhmDataTable',{attrs:{"dataList":_vm.dataQueryList,"itemsPerPageProps":_vm.itemsPerPage,"itemsPerPageOptionsProps":_vm.itemsPerPageOptions,"tableType":_vm.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST},on:{"GhmDataTAbleDeleteItemEvent":_vm.deleteDataQueryEmitter},scopedSlots:_vm._u([{key:"addNewRowButton",fn:function(){return [_c('AddNewDataQueryDialog',{on:{"RefreshListEvent":_vm.refreshDataQueryListEmitter}})]},proxy:true},{key:"editDataQueryDialogContentSlot",fn:function(ref){
var editDataQueryDialog = ref.editDataQueryDialog;
var dataQueryItem = ref.dataQueryItem;
var closeDataQueryDialog = ref.closeDataQueryDialog;
return [_c('AddNewDataQueryDialog',{attrs:{"isUpdateDialogProp":editDataQueryDialog,"dataQueryItemProps":dataQueryItem,"closeDataQueryDialog":closeDataQueryDialog},on:{"RefreshListEvent":_vm.refreshDataQueryListEmitter}})]}},{key:"restApiDialogContentSlot",fn:function(ref){
              var restApiDialog = ref.restApiDialog;
              var userActionType = ref.userActionType;
              var dataQueryItem = ref.dataQueryItem;
              var closeRestApiDialog = ref.closeRestApiDialog;
return [_c('RestApiDialog',{attrs:{"restApiDialogProps":restApiDialog,"userActionType":userActionType,"dataQueryItemProps":dataQueryItem,"closeRestApiDialog":closeRestApiDialog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }