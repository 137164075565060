<template>
  <v-row justify="center">
    <v-dialog
      v-model="addDataQueryDialogId"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          color="primary accent-2"
          top
          right
          absolute
          @click.stop="openDialog()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card height="100%">
        <v-form v-model="isValid" style="height: 100%">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="closedDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ isUpdateDialog ?
              $t('admin.dataQueries.updateDataQuery') :
              $t('admin.dataQueries.createDataQuery')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title>
              <v-btn
                class="ma-2"
                outlined
                v-if="isUpdateDialog"
                :disabled="!isValid"
                color="white"
                @click="onClickOfUpdateDataQuery()"
              >{{ $t('app.button.update') }}</v-btn>
              <v-btn
                class="ma-2"
                outlined
                v-else
                :disabled="!isValid"
                color="white"
                @click="onClickOfAddDataQuery()"
              >{{ $t('app.button.add') }}</v-btn>
            </v-toolbar-title>
          </v-toolbar>
          <v-card fill-height>
          <v-list>
            <v-subheader>{{ $t('admin.dataQueries.createQuery') }}</v-subheader>
            <v-list-item class="max-width-for-elements">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-text-field
                    prepend-icon="mdi-database-search"
                    :rules="displayNameRules"
                    v-model="dataQueryItem.displayName"
                    :label="$t('admin.dataQueries.displayName')"
                    required
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="max-width-for-elements">
              <v-list-item-content class="mb-n7">
                <v-list-item-subtitle>
                  <v-textarea
                    prepend-icon="comment"
                    :rules="descriptionRules"
                    v-model="dataQueryItem.description"
                    counter
                    class="py-2"
                    outlined
                    :label="$t('admin.dataQueries.description')"
                  ></v-textarea>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item class="max-width-for-elements">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-text-field
                    class="py-n10"
                    prepend-icon="mdi-key-variant"
                    :rules="primaryKeyFieldRules"
                    v-model="dataQueryItem.primaryKeyField"
                    :label="$t('admin.dataQueries.primaryKeyField')"
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="max-width-for-elements">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-text-field
                    prepend-icon="mdi-key-link"
                    v-model="dataQueryItem.templateForUniqueRowDesc"
                    :label="$t('admin.dataQueries.templateForUniqueRowDesc')"
                    required
                  ></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-switch
                    prepend-icon="mdi-state-machine"
                    v-model="dataQueryItem.disableSnapshot"
                    :label="$t('admin.dataQueries.disabledSnapshot')"
                  ></v-switch>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-radio-group @change="onChangeOfRadio()" prepend-icon="mdi-database-settings"
                  v-model="dataQueryItem.queryStringType" row>
                    <v-radio
                    :label="$t('admin.dataQueries.sqlQuery')"
                    :value="APP_ADMIN_QUERY_TYPE_SQL"></v-radio>
                    <v-radio
                    :label="$t('admin.dataQueries.jsonQuery')"
                    :value="APP_ADMIN_QUERY_TYPE_JSON"></v-radio>
                  </v-radio-group>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="max-width-for-elements"
            v-if="dataQueryItem.queryStringType === APP_ADMIN_QUERY_TYPE_SQL">
              <v-list-item-content class="mb-n5">
                <v-list-item-subtitle>
                  <v-textarea
                    :rules="queryStringRules"
                    v-model="dataQueryItem.queryString"
                    counter
                    class="ml-10 mb-n3 py-2"
                    outlined
                    :label="$t('admin.dataQueries.queryString')"
                  ></v-textarea>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="max-width-for-elements"
                v-if="dataQueryItem.queryStringType === APP_ADMIN_QUERY_TYPE_SQL">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-combobox class="ml-10"
                    v-model="dataQueryItem.currentConnectionString"
                    :items="currentDataSourceList"
                    required
                    :rules="connectionStringRules"
                    :label="$t('admin.dataQueries.currentConnectionString')"
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        <v-avatar
                          class="accent white--text"
                          left
                          v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
             <v-list-item class="max-width-for-elements"
            v-if="dataQueryItem.queryStringType === APP_ADMIN_QUERY_TYPE_JSON">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-combobox class="ml-10"
                    v-model="dataQueryItem.ungerboeckApiBaseUrl"
                    :items="ungerboeckApiBaseUrlList"
                    required
                    :rules="ungerboeckApiBaseUrlRules"
                    :label="$t('admin.dataQueries.ungerboeckApiBaseUrl')"
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        <v-avatar
                          class="accent white--text"
                          left
                          v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="max-width-for-elements"
             v-if="dataQueryItem.queryStringType === APP_ADMIN_QUERY_TYPE_JSON">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-text-field class="ml-10"
                    persistent-hint
                    hint="<span class='hintCss'>URL must start with '/api/v1/...'</span>"
                    v-model="dataQueryItem.queryString"
                    :rules="queryStringRestApiRules"
                    :label="$t('admin.dataQueries.jsonQuery')"
                    placeholder="URL must start with '/api/v1/...'"
                    required >
                    <template v-slot:message="{ message}">
                     <span v-html="message"></span>
                    </template>
                  </v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="max-width-for-elements">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-menu
                    v-model="validFromDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataQueryItem.validFrom"
                        :label="$t('admin.dataQueries.validFrom')"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dataQueryItem.validFrom"
                      @input="validFromDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="max-width-for-elements">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-menu
                    v-model="validToDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataQueryItem.validTo"
                        :label="$t('admin.dataQueries.validTo')"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dataQueryItem.validTo"
                    @input="validToDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="max-width-for-elements" style="margin-bottom: 100px;">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-combobox
                    prepend-icon="mdi-account-box-multiple"
                    v-model="dataQueryItem.availableRoles"
                    :items="rolesList"
                    :rules="applicationRoleRules"
                    :label="$t('admin.dataQueries.availableRoles')"
                    multiple
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        <v-avatar
                          class="accent white--text"
                          left
                          v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          </v-card>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Constants from '@/constants';
import AppMixin from '@/plugins/AppMixins';

export default {
  props: {
    isUpdateDialogProp: {
      type: Boolean,
      required: false,
    },
    dataQueryItemProps: {
      type: Object,
      required: false,
    },
    closeDataQueryDialog: {
      type: Function,
      required: false,
    },
  },

  mixins: [AppMixin],

  data() {
    return {
      isValid: false,
      validFromDateMenu: false,
      validToDateMenu: false,
      addDataQueryDialogId: false,
      rolesList: [],
      currentDataSourceList: [],
      ungerboeckApiBaseUrlList: [],
      ungerboeckApiBaseUrlObject: {},
      APP_ADMIN_QUERY_TYPE_SQL: Constants.APP_ADMIN_QUERY_TYPE_SQL,
      APP_ADMIN_QUERY_TYPE_JSON: Constants.APP_ADMIN_QUERY_TYPE_JSON,
      dataQueryItem: {
        id: null,
        displayName: '',
        description: '',
        primaryKeyField: '',
        templateForUniqueRowDesc: '',
        disableSnapshot: false,
        ungerboeckApiBaseUrl: '',
        queryString: '',
        currentConnectionString: '',
        validFrom: new Date().toISOString().substr(0, 10),
        validTo: new Date().toISOString().substr(0, 10),
        availableRoles: [],
        queryStringType: Constants.APP_ADMIN_QUERY_TYPE_SQL,
      },
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
    };
  },

  /**
   * mouning initial components
   */
  async mounted() {
    /**
     * Initialize data to get list of all available application roles.
     */
    await this.fetchAllAvaialbleRolesForDataQuery();

    /**
     * Initialize data to get list of current available data sources.
     */
    await this.fetchAllAvailableDataSourcesList();

    /**
     * Get all configured base url list.
     */
    await this.fetchAllUngerboeckBaseUrlList();
  },

  methods: {
    ...mapActions([
      'findAllRolesForUser',
      'fetchAllDataSourcesList',
      'addDataQuery',
      'updateDataQuery',
      'getUngerboeckBaseUrlsList',
    ]),

    /**
     * Close dialog when user click on cross.
     */
    closedDialog() {
      this.addDataQueryDialogId = false;
      this.closeDataQueryDialog();
    },

    /**
     * Method to fetch all available roles for Admin.
     */
    async fetchAllAvaialbleRolesForDataQuery() {
      console.log(
        '------------ fetching fetchAllAvaialbleRolesForDataQuery ********** ',
      );
      this.rolesList = await this.findAllRolesForUser();
      console.log(
        '------------ fetchAllAvaialbleRolesForDataQuery ********** ',
        this.rolesList,
      );
      // this.rolesList = responseData;
    },

    /**
     * Fetch list of all dataSources configured with backend
     */
    async fetchAllAvailableDataSourcesList() {
      console.log(
        '------------ fetching fetchAllAvailableDataSourcesList START ********** ',
      );
      this.currentDataSourceList = await this.fetchAllDataSourcesList();
      console.log(
        '------------ fetchAllAvailableDataSourcesList END ********** ',
        this.currentDataSourceList,
      );
    },

    /**
     * Fetch list of all Ungerboeck baseurl configured with backend
     */
    async fetchAllUngerboeckBaseUrlList() {
      console.log(
        '------------ fetching fetchAllUngerboeckBaseUrlList START ********** ',
      );
      this.ungerboeckApiBaseUrlObject = await this.getUngerboeckBaseUrlsList();
      if (this.ungerboeckApiBaseUrlObject) {
        Object.entries(this.ungerboeckApiBaseUrlObject).forEach((key) => {
          console.log('-----keys ---', key);
          if (key && key.length > 0) {
            this.ungerboeckApiBaseUrlList.push(`${key[0]} : ${key[1]}`);
          }
        });
      }
      console.log(
        '------------ fetchAllUngerboeckBaseUrlList END ********** ',
        this.ungerboeckApiBaseUrlList,
      );
    },

    /**
     * Method to add data query.
     */
    async onClickOfAddDataQuery() {
      console.log('------Add new Data query  ---------', this.dataQueryItem);
      this.isValidationError = false;
      const addDataQueryResponseMsg = await this.addDataQuery(this.dataQueryItem);
      console.log('------------- onClickOfAddDataQuery ---------------- > ', addDataQueryResponseMsg);

      // Call mixin method to handle response.
      this.handleApiResponse(addDataQueryResponseMsg, this.$t('admin.dataQueries.dataQuery'));
      this.$emit('RefreshListEvent', this.dataQueryItem.displayName);
      console.log('--------add new Data Query dialog ----- ', this.isValidationError);
      if (!this.isValidationError) {
        this.addDataQueryDialogId = false;
      }
    },

    /**
     * Method to update data query.
     */
    async onClickOfUpdateDataQuery() {
      console.log('------ Update Data Query  ---------', this.dataQueryItem);
      this.isValidationError = false;
      const updateDataQueryResponseMsg = await this.updateDataQuery(this.dataQueryItem);
      console.log('-------------updateDataQueryResponseMsg data query---------------- > ', updateDataQueryResponseMsg);

      // Call mixin method to handle response.
      this.handleApiResponse(updateDataQueryResponseMsg, 'Update Data Query');
      this.closeDataQueryDialog();
      this.$emit('RefreshListEvent', this.dataQueryItem.displayName);
      console.log('--------Update new user dialog ----- ', this.isValidationError);
      if (!this.isValidationError) {
        this.addDataQueryDialogId = false;
      }
    },

    /**
     * open dialog
     */
    openDialog() {
      console.log('-------------- open dialog ----------------');
      this.addDataQueryDialogId = true;
      this.isValidationError = false;
      this.dataQueryItem = {
        id: null,
        displayName: '',
        description: '',
        primaryKeyField: '',
        templateForUniqueRowDesc: '',
        disableSnapshot: false,
        ungerboeckApiBaseUrl: '',
        queryString: '',
        currentConnectionString: '',
        validFrom: new Date().toISOString().substr(0, 10),
        validTo: new Date().toISOString().substr(0, 10),
        availableRoles: [],
        queryStringType: Constants.APP_ADMIN_QUERY_TYPE_SQL,
      };
    },

    /**
     * On click of radio button.
     */
    onChangeOfRadio() {
      console.log('-------------- on click of radio button --------- :', this.dataQueryItem.queryStringType);
      this.dataQueryItem.queryString = '';
      this.dataQueryItem.currentConnectionString = '';
    },
  },
  computed: {
    ...mapGetters(['getApplicationError']),

    /**
     * Check if update or add call.
     */
    isUpdateDialog() {
      console.log(
        '----- is udpate call >>>>> ?? ', (!!((this.dataQueryItem && this.dataQueryItem.id))),
      );

      if (this.dataQueryItem && this.dataQueryItem.id) {
        console.log(' YES : Update call ');
        return true;
      }
      return false;
    },

    displayNameRules() {
      return [
        (value) => !!value || this.$t('queryDataValidations.displayNameIsRequired'),
        (value) => (value && value.length >= 5)
          || this.$t('queryDataValidations.displayNameMustHaveCharacter'),
        (value) => (value && value.length <= 100)
          || this.$t('queryDataValidations.diplayNameMaxCharacter'),
      ];
    },

    descriptionRules() {
      return [
        (value) => value.length <= 2000
          || this.$t('queryDataValidations.descriptionMaxCharacterLimit'),
      ];
    },

    primaryKeyFieldRules() {
      return [
        (value) => (value.length <= 100)
          || this.$t('queryDataValidations.primaryKeyFieldMaxCharacter'),
      ];
    },

    templateForUniqueRowDescRules() {
      return [
        (value) => !!value
          || this.$t('queryDataValidations.templateForUniqueRowIsRequired'),
        (value) => (value && value.length >= 1)
          || this.$t('queryDataValidations.templateForUniqueRowMustHaveCharacter'),
        (value) => (value && value.length <= 100)
          || this.$t('queryDataValidations.templateForUniqueRowMaxCharacter'),
      ];
    },
    queryStringRules() {
      return [
        (value) => !!value || this.$t('queryDataValidations.queryStringIsRequired'),
        (value) => (value && value.length >= 1)
          || this.$t('queryDataValidations.queryStringMustHaveCharacter'),
        (value) => (value && value.length <= 2000)
          || this.$t('queryDataValidations.queryStringMaxCharacter'),
      ];
    },

    queryStringRestApiRules() {
      return [
        (value) => !!value || this.$t('queryDataValidations.queryStringIsRequired'),
        (value) => (value && value.length >= 1)
          || this.$t('queryDataValidations.queryStringMustHaveCharacter'),
        (value) => (value && value.length <= 2000)
          || this.$t('queryDataValidations.queryStringMaxCharacter'),
        (value) => (value && value.startsWith('/api/v1/'))
          || this.$t('queryDataValidations.queryStringStartWithApi'),
      ];
    },

    applicationRoleRules() {
      return [
        (value) => value.length > 0
          || this.$t('queryDataValidations.roleShouldNotBeEmpty'),
      ];
    },

    connectionStringRules() {
      return [
        (value) => !!value || this.$t('queryDataValidations.dataSourceShouldNotBeEmpty'),
        (value) => value.length > 0
          || this.$t('queryDataValidations.dataSourceShouldNotBeEmpty'),
      ];
    },

    ungerboeckApiBaseUrlRules() {
      return [
        (value) => value.length > 0
          || this.$t('queryDataValidations.baseUrlShouldNotBeEmpty'),
      ];
    },

    currentDataSourceRules() {
      return [
        (value) => value.length > 0
          || this.$t('queryDataValidations.dataSourceShouldNotBeEmpty'),
      ];
    },
  },

  watch: {

    isUpdateDialogProp(newVal, oldVal) {
      console.log(
        '-------isUpdateDialogProp watch is ----- ',
        newVal,
        ' : ',
        oldVal,
      );
      this.addDataQueryDialogId = newVal;
    },

    /**
     * Copy existing property values for editing.
     */
    dataQueryItemProps(newVal, oldVal) {
      console.log('-------dataQueryItemProps watch is ----- ', newVal, ' : ', oldVal);
      if (newVal && Object.prototype.hasOwnProperty.call(newVal, 'id')) {
        this.dataQueryItem.id = newVal.id;
        this.dataQueryItem.displayName = newVal.displayName;
        this.dataQueryItem.description = newVal.description;
        this.dataQueryItem.primaryKeyField = newVal.primaryKeyField;
        this.dataQueryItem.templateForUniqueRowDesc = newVal.templateForUniqueRowDesc;
        this.dataQueryItem.disableSnapshot = newVal.disableSnapshot;
        this.dataQueryItem.ungerboeckApiBaseUrl = newVal.ungerboeckApiBaseUrl;
        this.dataQueryItem.queryString = newVal.queryString;
        this.dataQueryItem.currentConnectionString = newVal.currentConnectionString;
        this.dataQueryItem.validFrom = newVal.validFrom;
        this.dataQueryItem.validTo = newVal.validTo;
        this.dataQueryItem.availableRoles = newVal.availableRoles;
        this.dataQueryItem.queryStringType = newVal.queryStringType;
      }
    },
  },
};
</script>
<style scoped>
.max-width-for-elements {
  max-width: 800px !important;
}
.hintCss{
  color: RED !important;
}
</style>
