<template>
<div style="height: 100vh;" class="mt-10">
   <GhmDataTable
      :dataList="dataQueryList"
      :itemsPerPageProps="itemsPerPage"
      :itemsPerPageOptionsProps="itemsPerPageOptions"
      @GhmDataTAbleDeleteItemEvent="deleteDataQueryEmitter"
      :tableType="APP_DATATABLE_TYPE_ADMIN_QUERY_LIST">
      <template v-slot:addNewRowButton>
        <AddNewDataQueryDialog
              @RefreshListEvent="refreshDataQueryListEmitter" />
      </template>
      <template
      v-slot:editDataQueryDialogContentSlot=
      "{editDataQueryDialog, dataQueryItem, closeDataQueryDialog}">
      <AddNewDataQueryDialog
              :isUpdateDialogProp="editDataQueryDialog"
              :dataQueryItemProps="dataQueryItem"
                    @RefreshListEvent="refreshDataQueryListEmitter"
                    :closeDataQueryDialog="closeDataQueryDialog" />
      </template>
      <template v-slot:restApiDialogContentSlot="{restApiDialog, userActionType, dataQueryItem,
              closeRestApiDialog}">
        <RestApiDialog
            :restApiDialogProps="restApiDialog"
            :userActionType="userActionType"
            :dataQueryItemProps="dataQueryItem"
            :closeRestApiDialog="closeRestApiDialog" />
      </template>
   </GhmDataTable>
</div>
</template>
<script>
import GhmDataTable from '@/components/app/GhmDataTable.vue';
import { mapActions } from 'vuex';
import Constants from '@/constants';
import AddNewDataQueryDialog from '@/components/admin/AddNewDataQueryDialog.vue';
import RestApiDialog from '@/components/admin/RestApiDialog.vue';
import AppMixin from '@/plugins/AppMixins';
import ItemsPerPageMixin from '@/plugins/ItemsPerPageMixin';

export default {

  props: {
    /* isNewRoleAddedEvent: {
      type: String,
      required: true,
    }, */
  },

  components: {
    GhmDataTable,
    AddNewDataQueryDialog,
    RestApiDialog,
  },

  mixins: [AppMixin, ItemsPerPageMixin],

  /**
   * Initialize data when component mounted. (page initial render)
   */
  async mounted() {
    // Fetch all data query from DB
    await this.fetchAllDataQueriesListForAdmin();
    this.setPageTitleOfThisPage();
  },

  data() {
    return {
      tab: null,
      pageTitle: 'app.menu.manageQueries',
      dataQueryList: [],
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
      APP_DATATABLE_TYPE_ADMIN_QUERY_LIST:
        Constants.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST,
    };
  },

  methods: {

    ...mapActions(['setPageTitle', 'findAllDataQueriesList', 'deleteDataQueryByAdmin']),

    /**
     * Method to fetch all available users for Admin.
     */
    async fetchAllDataQueriesListForAdmin() {
      console.log('------------ fetching all data queries ********** ');
      this.dataQueryList = await this.findAllDataQueriesList();
      if (this.dataQueryList) {
        this.calculateItemsPerPage(this.dataQueryList.length);
      }
      console.log('------------ finished data query list ********** ', this.dataQueryList);
    },

    /**
     * Delete Data Query.
     */
    async deleteDataQueryEmitter(id) {
      console.log('------Delete Data Query Event---------------', id);
      if (id) {
        const deleteDataQueryResponse = await this.deleteDataQueryByAdmin(id);

        // Call mixin method to handle response.
        this.handleApiResponse(deleteDataQueryResponse, 'Data Query');

        // Refresh list after deletion.
        await this.fetchAllDataQueriesListForAdmin();
      }
    },

    /**
     * Refresh data query list when new data query gets added.
     */
    async refreshDataQueryListEmitter(event) {
      if (event) {
        await this.fetchAllDataQueriesListForAdmin();
      }
    },

    setPageTitleOfThisPage() {
      console.log('--------------user queries page tityle METHOD setPageTitleOfThisPage ---------------');
      this.setPageTitle(this.pageTitle);
    },

  },

};
</script>
<style scoped>
</style>
