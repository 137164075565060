<template>
  <v-row justify="center">
    <v-dialog v-model="restApiDialog" persistent max-width="800px">
       <v-form v-model="isValid">
        <v-card>
          <v-card-title>
            <span class="headline">{{restApiDialogHeader}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
             <!-- <v-row>
                <v-col cols="12" sm="8" md="12">
                  <v-text-field
                    label="Api Url*"
                    required
                    :value="getRestApiUrlFromProps"
                    :disabled="true"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                 <v-col cols="12" sm="8" md="12">
                  <v-list v-for="item in parametersArray" :key="item.id">
                      <v-list-item class="meuItemCss px-2">
                        <v-list-item-icon>
                           <v-icon>{{item.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                          class="nav-drawer-title-text">
                            <v-text-field
                                :label="item.itemName + '*'"
                                required
                                :rules="parametersInputRules"
                                v-model="item.itemValue">
                            </v-text-field>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                </v-list>
                </v-col>
              </v-row>
            </v-container>
            <small>{{ $t('indicatesRequiredField')}}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="blue darken-1" @click.stop="closedDialog()">
              {{ $t('app.button.close') }}
            </v-btn>
            <v-btn :disabled="!isValid"
                    outlined color="blue darken-1"
                    @click.stop="fetchDataFromRestApi()">
              {{ restApiActionButton }}
            </v-btn>
          </v-card-actions>
        </v-card>
       </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import Constants from '@/constants';
import { mapActions } from 'vuex';

export default {
  props: {
    restApiDialogProps: {
      type: Boolean,
      required: true,
    },

    dataQueryItemProps: {
      type: Object,
      requied: true,
    },

    closeRestApiDialog: {
      type: Function,
      required: false,
    },

    userActionType: {
      type: String,
      required: true,
    },

  },

  data() {
    return {
      restApiDialog: false,
      parametersArray: [],
      isValid: false,
      USER_LIST_ACTION_TYPE_CSV_DOWNLOAD: Constants.USER_LIST_ACTION_TYPE_CSV_DOWNLOAD,
      USER_LIST_ACTION_TYPE_JSON_VIEW: Constants.USER_LIST_ACTION_TYPE_JSON_VIEW,
    };
  },

  methods: {

    ...mapActions(['isAdminUser']),

    /**
     * Close dialog
     */
    closedDialog() {
      this.restApiDialog = false;
      this.closeRestApiDialog();
    },

    /**
     * Fetch data from rest api.
     */
    fetchDataFromRestApi() {
      console.log(
        '---------------fetchDataFromRestApi --------------- : ',
        this.dataQueryItemProps,
      );

      if (this.dataQueryItemProps && this.dataQueryItemProps.queryString) {
        console.log('----------- fetchDataFromRestApi 11 --------- ');
        let restApiUrl = this.dataQueryItemProps.queryString;
        console.log('----------- fetchDataFromRestApi 22 --------- ', restApiUrl);
        if (this.parametersArray && this.parametersArray.length > 0) {
          console.log('----------- parametersArray --------- ', this.parametersArray);
          (this.parametersArray).forEach((parameter) => {
            console.log('----------- for each param : START --------- ', parameter);
            restApiUrl = restApiUrl.replace(`{${parameter.itemName}}`, parameter.itemValue);
            console.log('----------- for each param : END --------- ', parameter);
          });
          console.log('----------- restApiUrl --------- ', restApiUrl);
          if (restApiUrl) {
            console.log('----------- this.userActionType --------- ', this.userActionType);
            if (this.userActionType === this.USER_LIST_ACTION_TYPE_CSV_DOWNLOAD) {
              console.log('----------- Call USER_LIST_ACTION_TYPE_CSV_DOWNLOAD --------- ');
              this.$emit('FetchDataQueryFromRestApiEmitter', {
                restUrlParam: restApiUrl, dataQueryIdForApi: this.dataQueryItemProps.id, fileName: `${this.dataQueryItemProps.displayName}_${this.dataQueryItemProps.id}`, userActionType: this.userActionType,
              });
            } else if (this.userActionType === this.USER_LIST_ACTION_TYPE_JSON_VIEW) {
              console.log('----------- Call USER_LIST_ACTION_TYPE_CSV_DOWNLOAD --------- ');
              this.$emit('FetchDataQueryFromRestApiEmitter', {
                restUrlParam: restApiUrl, dataQueryIdForApi: this.dataQueryItemProps.id, fileName: `${this.dataQueryItemProps.displayName}_${this.dataQueryItemProps.id}`, userActionType: this.userActionType,
              });
            } else {
              console.log('----------- Call false --------- ');
              if (this.isAdminUser) {
                console.log('----------- RestApiDialog : isAdmin : true --------- ');
                this.$router.push({
                  name: 'AdminQueryDetails',
                  params: {
                    restUrlParam: restApiUrl,
                    dataQueryIdForApi: this.dataQueryItemProps.id,
                    dataQueryName: this.dataQueryItemProps.displayName,
                  },
                });
              } else {
                console.log('----------- RestApiDialog : isAdmin : false --------- ');
                this.$router.push({
                  name: 'UserQueryDetails',
                  params: {
                    restUrlParam: restApiUrl,
                    dataQueryIdForApi: this.dataQueryItemProps.id,
                    dataQueryName: this.dataQueryItemProps.displayName,
                  },
                });
              }
            }
          }
        }
      }
      this.restApiDialog = false;
      this.closeRestApiDialog();
    },
  },

  computed: {

    /**
     * Query String Rest api from url.
     */
    getRestApiUrlFromProps() {
      return this.dataQueryItemProps.queryString;
    },

    parametersInputRules() {
      return [
        (value) => !!value || this.$t('queryDataValidations.parameterIsRequired'),
        (value) => (value && value.length >= 1)
          || this.$t('queryDataValidations.parameterMustHaveCharacter'),
        (value) => (value && value.length <= 100)
          || this.$t('queryDataValidations.parameterMaxCharacter'),
      ];
    },

    /**
     * Rest Api dialog header.
     */
    restApiDialogHeader() {
      if (this.userActionType === this.USER_LIST_ACTION_TYPE_CSV_DOWNLOAD) {
        return this.$t('user.queries.downloadCsv');
      } if (this.userActionType === this.USER_LIST_ACTION_TYPE_JSON_VIEW) {
        return this.$t('user.queries.viewAsJson');
      }
      return this.$t('user.queries.addParameters');
    },

    /**
     * Rest Api action button label.
     */
    restApiActionButton() {
      if (this.userActionType === this.USER_LIST_ACTION_TYPE_CSV_DOWNLOAD) {
        return this.$t('app.button.downloadAsCsv');
      } if (this.userActionType === this.USER_LIST_ACTION_TYPE_JSON_VIEW) {
        return this.$t('user.queries.viewAsJson');
      }
      return this.$t('app.button.getDetails');
    },
  },

  watch: {
    /**
     * dialog open/close props.
     */
    restApiDialogProps(newProps) {
      console.log('------------ rest api dialog props ', newProps);
      this.restApiDialog = newProps;
    },

    dataQueryItemProps(newVal) {
      console.log('------watch dataQueryItemProps 1 ---', newVal);
      if (newVal) {
        const { queryString } = newVal;
        console.log('-------- RestApiDialog mounted 22 -------', queryString);
        if (queryString) {
          const indexOfFirstParam = queryString.indexOf('/{');
          if (indexOfFirstParam !== -1) {
            const urlParameters = queryString.substring(
              indexOfFirstParam + 2,
              queryString.length,
            );
            console.log('-------- RestApiDialog mounted 33 -------', urlParameters);
            if (urlParameters) {
              const replaceAllClosedBracket = urlParameters.replaceAll('}', '');
              console.log('--------replaceAllClosedBracket -------', replaceAllClosedBracket);
              if (replaceAllClosedBracket) {
                const urlParamArray = replaceAllClosedBracket.split('/{');
                console.log('-------- <<< >>>>>>>> -------', urlParamArray);
                if (urlParamArray) {
                  const urlParamObjectArray = (urlParamArray).map((item, index) => {
                    let currentItem = item;
                    if (currentItem) {
                      const slashIndex = (currentItem).indexOf('/');
                      if (slashIndex !== -1) {
                        currentItem = (currentItem).substring(0, slashIndex);
                      }
                    }
                    console.log('-------------- currentItem -------- ', currentItem);
                    return {
                      id: index, icon: `mdi-numeric-${index + 1}-circle`, itemName: currentItem, itemValue: '',
                    };
                  });
                  console.log('----------urlParamObjectArray : ', urlParamObjectArray);
                  this.parametersArray = urlParamObjectArray;
                }
              }
            }
          }
        }
      }
    },

  },
};
</script>
